<template>
  <div>
    <Managejob />
  </div>
</template>
<script>
import Managejob from "@/components/job/Managejob";
export default {
  components: {
    Managejob,
  },
  created() {
  },
};
</script>